'use strict'

import {createRouter, createWebHistory} from 'vue-router'
import App from '@/App'
import NotFound from '@/pages/NotFound'

const routes = [
  {
    path: '/',
    component: App,
  },
  {
    path: '/privacyverklaring',
    component: App,
    props () {
      const contentType = 'privacy-statement'

      return { contentType }
    }
  },
  {
    path: '/:contentType/:slug',
    component: App,
    props (route) {
      const slug = route.params.slug
      const contentType = route.params.contentType

      return { slug, contentType }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
