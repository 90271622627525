<template>
<div class="blur-sm">
  <img class="w-full h-full" :src="'data:image/png;base64,' + src" :alt="state.customer.name">
</div>
</template>

<script>
import state from '@/state'

export default {
  name: 'DynamicBackground',
  props: {
    src: String
  },
  setup () {
    return {
      state
    }
  }
}
</script>
