<template>
  <div class="fixed h-screen w-screen left-0 top-0 py-10 px-5 overflow-y-auto flex items-start justify-center bg-black bg-opacity-50" @click="close">
    <div class="bg-white rounded-lg shadow-lg max-w-[900px]" @click.stop>
      <h3 class="mt-10 text-center text-5xl italic leading-6 font-medium text-gray-900 pb-10">Privacyverklaring</h3>

      <hr class="pb-0.5" />

      <div class="mt-2 p-5">
        <div class="pb-10">
          <p>{{ customer.name }} is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
          <p>Contactgegevens:</p>
          <p>{{ customer.contact.address }}</p>
          <p>{{ customer.contact.postal_code }} {{ customer.contact.city }}</p>
          <p>{{ customer.contact.phone_number }}</p>
          <p>{{ customer.website }}</p>
        </div>
        <p>Wij zijn te bereiken via {{ customer.contact.email }}.</p>
        <div class="py-10">
          <span class="font-bold">Persoonsgegevens die wij verwerken</span>
          <p>{{ customer.name }} verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze gegevens zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</p>
          <ul class="list-disc pl-[20px]">
            <li>Voor- en achternaam</li>
            <li>Geslacht</li>
            <li>Geboortedatum</li>
            <li>Adresgegevens</li>
            <li>E-mailadres</li>
            <li>IP-adres</li>
            <li>Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
            <li>Gegevens over uw activiteiten op onze website</li>
            <li>Internetbrowser en apparaat type</li>
          </ul>
        </div>
        <div class="pb-10">
          <span class="font-bold">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</span>
          <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via {{ customer.contact.email }}, dan verwijderen wij deze informatie.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</span>
          <p>{{ customer.name }} verwerkt uw persoonsgegevens voor de volgende doelen:</p>
          <ul class="list-disc pl-[20px]">
            <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
            <li>{{ customer.name }} analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.</li>
          </ul>
        </div>
        <div class="pb-10">
          <span class="font-bold">Geautomatiseerde besluitvorming</span>
          <p>{{ customer.name }} neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van {{ customer.name }}) tussen zit.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Hoe lang we persoonsgegevens bewaren</span>
          <p>{{ customer.name }} bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren een bewaartermijn van zeven jaar.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Delen van persoonsgegevens met derden</span>
          <p>{{ customer.name }} deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. {{ customer.name }} blijft verantwoordelijk voor deze verwerkingen.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Cookies, of vergelijkbare technieken, die wij gebruiken</span>
          <p>{{ customer.name }} gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. {{ customer.name }} gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze website hebben wij u al geïnformeerd over deze cookies en hebben we uw toestemming gevraagd voor het plaatsen ervan. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
            instellingen van uw browser verwijderen.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Gegevens inzien, aanpassen of verwijderen</span>
          <p>U hebt het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast hebt u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door {{ customer.name }} en hebt u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar {{ customer.contact.email }}. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan
            het paspoort),paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. {{ customer.name }} wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens.</p>
        </div>
        <div class="pb-10">
          <span class="font-bold">Hoe wij persoonsgegevens beveiligen</span>
          <p>{{ customer.name }} neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat uw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via {{ customer.contact.email }}.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import state from '@/state'

export default {
  computed: {
    customer () {
      return state.customer
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
