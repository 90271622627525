'use strict'

import { createApp } from 'vue'
import { RouterView } from 'vue-router'
import router from './router'
import 'tailwindcss/tailwind.css'
import state from '@/state'

const MAX_DOMAIN_PARTS_LENGTH = 3

const parts = window.location.host.split('.')

if(parts.length !== MAX_DOMAIN_PARTS_LENGTH || parts[0] === 'www') {
  state.setCustomerDomain(null)

  window.location.assign('https://nexxtmove.nl/')
}
else {
  state.setCustomerDomain(parts[0])
}

const app = createApp(RouterView).use(router)

app.mount('#app')
