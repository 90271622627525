'use strict'

import {reactive} from 'vue'
import api from '@/utilities/Api'

const state = {
  customer: reactive({}),
  content: reactive({}),

  load (customer) {
    if(customer === null) {
      this.$router.push({name: 'NotFound'})
    }

    return api.get(`/customers/${customer}`, {
      mobile: this.customer.onMobile || false,
    }).then((result) => {
      this.customer = {
        ...result.data.data.customer,
        ...this.customer
      }

      this.customer.theme = result.data.data.theme

      const prefetch = document.createElement('link')

      prefetch.rel = 'prefetch'
      prefetch.as = 'document'
      prefetch.href = this.customer.website

      document.head.append(prefetch)
    })
  },

  loadContent(contentType, slug) {
    if(contentType === null || slug === null) {
      window.location.assign(state.customer.website)
    }

    return api.get(`/content/${contentType}/${slug}`).then((result) => {
      this.content = {
        ...result.data.data,
        ...this.content
      }
    })
  },

  getCustomerDomain() {
    return this.customer.domain || null
  },

  setCustomerDomain(customerDomain) {
    this.customer.domain = customerDomain
  },

  onMobile() {
    const isMobile = window.matchMedia || window.msMatchMedia

    if (isMobile) {
      const matchMobile = isMobile('(pointer:coarse)')
      return matchMobile.matches
    }

    return false
  }
}

export default state
