<template>
  <div class="fixed h-screen w-screen left-0 top-0 py-10 px-5 overflow-y-auto flex items-start justify-center bg-black bg-opacity-50" @click="close">
    <div class="bg-white rounded-lg shadow-lg max-w-[900px]" @click.stop>
      <img :src="main_image.url" :alt="main_image.name" class="w-full h-auto rounded-t-lg">

      <div class="p-5">
        <h1 class="text-3xl font-light text-gray-900 pb-6">{{ title }}</h1>
        <hr class="pb-0.5" />
        <div v-html="content" class="article-content mt-2 "></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    main_image: Object, // eslint-disable-line camelcase
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.article-content {
  word-break: break-word;
}

.article-content p {
  @apply mb-5;
}

.article-content a {
  @apply text-slate-600 underline;
}

.article-content h1 {
  @apply text-3xl;
}

.article-content h2 {
  @apply text-2xl mb-5;
}

.article-content h3 {
  @apply text-xl mb-1;
}
</style>
