<template>
  <Loader v-if="loading" />

  <div v-else-if="!notFound">
    <DynamicBackground :src="state.customer.imageData"></DynamicBackground>

    <ArticleModal v-if="contentType === 'articles'" :title="state.content.title" :content="state.content.content" :main_image="state.content.main_image" @close="closeModal"></ArticleModal>
    <ProductModal v-else-if="contentType === 'products'" :product="state.content" @close="closeModal"></ProductModal>
    <PrivacyStatementModal v-else-if="contentType === 'privacy-statement'" @close="closeModal"></PrivacyStatementModal>
  </div>
  <NotFound v-else />
</template>

<script>
import DynamicBackground from '@/components/DynamicBackground'
import Loader from '@/components/Loader'
import state from '@/state'
import ArticleModal from '@/components/modals/ArticleModal'
import ProductModal from '@/components/modals/ProductModal'
import NotFound from '@/pages/NotFound'
import PrivacyStatementModal from '@/components/modals/PrivacyStatementModal'

export default {
  name: 'App',
  components: {
    PrivacyStatementModal,
    NotFound,
    ArticleModal,
    ProductModal,
    DynamicBackground,
    Loader,
  },
  props: {
    slug: String,
    contentType: String,
  },
  setup () {
    return { state }
  },
  data () {
    return {
      loading: false,
      notFound: false
    }
  },
  created () {
    let styleElement = document.createElement('style')
    this.loading = true

    styleElement.type = 'text/css'

    state.onMobile()

    state.load(state.getCustomerDomain()).catch((error) => {
      if(error.status === 404 || !error.status){
        this.notFound = true
        this.loading = false
      }
    }).finally(() => {
      if(state.customer.website) {
        if (this.contentType === undefined && this.slug === undefined) {
          window.location.assign(state.customer.website)
        }

        for (const fontKey in state.customer.theme.font) {
          let linkElement = document.createElement('link')

          linkElement.rel = 'stylesheet'
          linkElement.href = 'https://fonts.googleapis.com/css2?family='+state.customer.theme.font[fontKey]+':ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'

          document.head.append(linkElement)

          /* eslint-disable default-case */
          switch (fontKey) {
          case 'heading':
            styleElement.innerHTML = styleElement.innerHTML
                  + 'h1, h2, h3, h4, h5, h6 {font-family: ' + state.customer.theme.font.heading + '}'
                  + 'header {font-family: ' + state.customer.theme.font.heading + '}'
            break
          case 'main':
            styleElement.innerHTML = styleElement.innerHTML
                + 'body {font-family: ' + state.customer.theme.font.main + '}'
            break
          }
        }

        document.head.append(styleElement)

        if (this.contentType !== 'privacy-statement') {
          state.loadContent(this.contentType, this.slug).catch((error) => {
            if (error.status === 404 || !error.status) {
              this.notFound = true
            }
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.loading = false
        }
      }
    })
  },
  methods: {
    closeModal() {
      window.location.assign(state.customer.website)
    }
  }
}
</script>
